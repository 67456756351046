import * as React from 'react';
import PropTypes from 'prop-types';
import { decodeEntities } from 'utils/decode-wrapper';
import Sticky from 'react-sticky-el';
import Flyout from 'components/Posts/Flyout';
import { StickySocialShare } from 'components/Posts/StickySocialShare';

const StickySocialButtons = React.forwardRef((props, ref) => {
	const { post, ...rest } = props;
	const featuredImage = post.featured_media?.id ? post.featured_media : {};

	const twitterTitleContent = post?.seo_twitter?.twitter_title?.content
		? post.seo_twitter.twitter_title?.content
		: '';

	return (
		<div {...rest} ref={ref} className="article-sticky-social">
			<Sticky
				className="story-stick"
				mode="bottom"
				stickyClassName="story-stick--sticky"
				positionRecheckInterval={25}
			>
				<div className="story-stick--sticky__wrap">
					<StickySocialShare
						title={decodeEntities(post.title)}
						url={post.link}
						image={featuredImage}
						twitterTitle={twitterTitleContent}
					/>
					{post?.related_story?.link && <Flyout content={post.related_story} />}
				</div>
			</Sticky>
		</div>
	);
});

StickySocialButtons.propTypes = {
	post: PropTypes.object.isRequired,
};

export default StickySocialButtons;
