import React, { Component } from 'react';
import dynamic from 'next/dynamic';
import { Link } from 'components/Html';
import { PostContentContext } from 'components/Posts/Content';
import PostGridItem from 'components/Posts/Blogroll/PostGridItem';
import { __ } from '@wordpress/i18n';

const DynamicSliderWithNoSSR = dynamic(
	() => import('components/Slider').then((mod) => mod.Homepage),
	{ ssr: false },
);

const { Consumer } = PostContentContext;

class RelatedPosts extends Component {
	relatedAchiveUrl = () => {
		if (this.props.archivepath) {
			return (
				<Link className="related-posts__archive-link" href={this.props.archivepath}>
					<span className="related-posts__archive-link-text">
						{__('See All', 'starbucks')}
					</span>

					<span
						className="related-posts__archive-link-text related-posts__archive-link-text--dummy"
						aria-hidden="true"
					>
						{__('See All', 'starbucks')}
					</span>
					<div className="related-posts__archive-link-underline" />
				</Link>
			);
		}
	};

	relatedTitle = () => {
		const title = this.props.title ? this.props.title : __('Related Posts', 'starbucks');

		return (
			<h2 className={this.getVariationClassName('related-posts__title')}>
				{title}
				{this.relatedAchiveUrl()}
			</h2>
		);
	};

	getVariationClassName = (className) =>
		`${className} ${className}--${
			this.props.variationProps && this.props.variationProps.variation
				? this.props.variationProps.variation
				: this.props.type
		}`;

	render() {
		const { currentPost } = this.props;

		// don't show related posts for productions
		// (original is the old taxonomy name for productions)
		if (currentPost && currentPost.original && !!currentPost.original.length) {
			return null;
		}

		const Title = this.relatedTitle;

		const isInterrupter =
			this.props.variationProps && this.props.variationProps.variation === 'interrupter';

		const ContainerEl =
			isInterrupter && window && window.innerWidth < 900 ? DynamicSliderWithNoSSR : 'div';

		const displayExcerpt = !isInterrupter;

		return (
			<section className={this.getVariationClassName('related-posts')}>
				<div className="related-posts__header">
					<Title />
				</div>

				<ContainerEl className="related-posts__grid post-grid">
					{this.props.posts &&
						this.props.posts.map((post) => {
							return (
								<PostGridItem
									post={post}
									key={post.id}
									type={this.props.type}
									terms={this.props.terms}
									displayExcerpt={displayExcerpt}
								/>
							);
						})}
				</ContainerEl>
			</section>
		);
	}
}

const RelatedPostsWithContext = (props) => (
	<Consumer>{(context) => <RelatedPosts {...props} {...context} />}</Consumer>
);

export default RelatedPostsWithContext;
