import * as React from 'react';
import PropTypes from 'prop-types';
import { Link, Svg } from 'components/Html';
import { __ } from '@wordpress/i18n';

export default function SeriesArchive({ title, link, logo }) {
	const taxName = __('Productions', 'starbucks');

	return (
		<div className="article-category">
			<Link href={link || '/stories'} className="article-category__link">
				{logo ? (
					<Svg content={logo} alt={title} className="article-category__image" />
				) : (
					<>
						<p className="article-category__name">{title}</p>
						<p className="article-category__type">{taxName}</p>
					</>
				)}
			</Link>
		</div>
	);
}

SeriesArchive.propTypes = {
	title: PropTypes.string,
	link: PropTypes.string,
	logo: PropTypes.string,
};
