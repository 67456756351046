/**
 * Simple throttling function for waiting a set amount of time after the last keypress
 * So we don't overload the server with too many requests at once
 *
 * @param fn
 * @param delay
 * @returns {Function}
 */
export function debounce(fn, delay) {
	let timer = null;

	return function () {
		const context = this;
		const args = arguments;

		window.clearTimeout(timer);

		timer = window.setTimeout(function () {
			fn.apply(context, args);
		}, delay);
	};
}
