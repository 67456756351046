import * as React from 'react';
import PropTypes from 'prop-types';
import Image from 'next/image';
import ChevronRight from 'assets/icons/chevron-right.svg';
import { __ } from '@wordpress/i18n';
import SeriesArchive from './SeriesArchive';

export default function UpNext({ series = {}, posts = [] }) {
	const [next, prev] = posts;

	return (
		<>
			<Title series={series} />

			<section className="next-prev">
				<Button post={prev} direction="previous" />

				<Button post={next} direction="next" />
			</section>
		</>
	);
}

const Title = ({ series = {} }) => {
	return (
		<span className="next-prev__title sb-headline--section-sm">
			<span className="next-prev__title-text">{__('Keep exploring', 'starbucks')}</span>

			<SeriesArchive {...series} />
		</span>
	);
};

const Button = ({ post = {}, direction }) => {
	const image = post?.image?.src ? post.image : {};
	return (
		<a href={post.link} className={`next-prev-button next-prev-button--${direction}`}>
			<div
				className={`next-prev-button__arrow-area next-prev-button__arrow-area--${direction}`}
			>
				<ChevronRight
					className={`next-prev-button__arrow next-prev-button__arrow--${direction}`}
				/>
			</div>

			<div className={`next-prev-button__text next-prev-button__text--${direction}`}>
				<h3 className="next-prev-button__direction">{direction}</h3>
				<h2 className="next-prev-button__title">{post.title}</h2>

				{post.subtitle && (
					<h3
						className="next-prev-button__subtitle"
						dangerouslySetInnerHTML={{
							__html: post.subtitle,
						}}
					/>
				)}
			</div>

			<div className={`next-prev-button__image next-prev-button__image--${direction}`}>
				<Image src={image.src} alt={post.title} layout="fill" objectFit="cover" />
			</div>
		</a>
	);
};

UpNext.propTypes = {
	series: PropTypes.object,
	posts: PropTypes.arrayOf(PropTypes.object),
};
