import * as React from 'react';
import PropTypes from 'prop-types';
import BaseRelatedPosts from 'components/Posts/RelatedPosts';

export default function RelatedPosts({ type, title, posts = [], variationProps = {}, ...rest }) {
	return posts.length ? (
		<BaseRelatedPosts
			{...rest}
			title={title}
			type={type}
			posts={posts}
			terms={{}}
			variationProps={variationProps}
		/>
	) : null;
}

RelatedPosts.propTypes = {
	type: PropTypes.oneOf(['related']),
	title: PropTypes.string,
	posts: PropTypes.arrayOf(PropTypes.object),
	variationProps: PropTypes.object,
};
