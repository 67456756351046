import * as React from 'react';
import PropTypes from 'prop-types';
import { getReadingTime, longDateFormat } from 'utils/date';
import { __, sprintf } from '@wordpress/i18n';
import { useRouter } from 'next/router';

export default function Metadata({ post }) {
	const router = useRouter();
	const { locale } = router;
	const formattedDate = post.date ? longDateFormat(post.date, locale) : '';
	const readingTime = post.content?.rendered ? getReadingTime(post.content.rendered) : '';

	const sep = ' •';
	const author = post.author ? `By ${post.author}` : '';
	const date = formattedDate ? `${author && sep} ${formattedDate}` : '';
	const readingTimeTranslated = sprintf(__('%d min read', 'starbucks'), readingTime);
	const readTime = readingTime ? `${sep} ${readingTimeTranslated}` : '';

	let metaString = `${author}${date}`;

	if (post.hide_date) {
		metaString = `${author}`;
	}

	return metaString ? (
		<div className="article-meta">
			{metaString && (
				<p className="article-meta__author">
					{metaString}
					<span className="article-meta__read-time">{readTime}</span>
				</p>
			)}
		</div>
	) : null;
}

Metadata.propTypes = {
	post: PropTypes.object,
};
