import React, { Component } from 'react';
import Image from 'next/image';
import striptags from 'striptags';
import { Link } from 'components/Html';
import { decodeEntities } from 'utils/decode-wrapper';
import { getInlineSvg } from 'utils/image';
import VideoThumbnail from 'components/Images/VideoThumbnail';
import { RawHTML } from 'components/Html/Raw/html';

class PostGridItem extends Component {
	static decorateClasses(type) {
		if (!type) {
			return {
				containerClass: '',
				linkClass: '',
				titleContainClass: '',
				titleClass: '',
				metaCatClass: '',
			};
		}
		return {
			containerClass: ` post-grid-item--${type}`,
			linkClass: ` post-grid-item__link--${type}`,
			titleContainClass: ` post-grid-item__title-contain--${type}`,
			titleClass: ` post-grid-item__title--${type}`,
			metaCatClass: ` post-grid-item__meta-cat--${type}`,
		};
	}

	postTitle = () => {
		const { titleClass } = PostGridItem.decorateClasses(this.props.type);
		return (
			<RawHTML
				tagName="h2"
				className={`post-grid-item__title${titleClass}`}
				value={this.props.post.title}
			/>
		);
	};

	taxonomySupHead = () => {
		if (this.props.type === 'featured') {
			const { post, terms } = this.props;
			const originalTerm = post.original && post.original[0] && terms[post.original[0]];
			let originalImg = '';

			const taxName = this.props.primaryTerm && this.props.primaryTerm.name;
			const { metaCatClass } = PostGridItem.decorateClasses(this.props.type);

			if (originalTerm && this.props.posts) {
				originalImg = this.props.posts[originalTerm.meta.logo_image_id];
			}

			if (originalTerm && originalImg) {
				return (
					<span className={`post-grid-item__meta-cat${metaCatClass}`}>
						<img
							className="image-to-svg"
							src={originalImg.source_url}
							alt={originalTerm.name}
							onLoad={() => this.loadHandlerInlineSvg()}
						/>
					</span>
				);
			}
			return <span className={`post-grid-item__meta-cat${metaCatClass}`}>{taxName}</span>;
		}
	};

	originalsArchive = () => {
		if (
			this.props.type === 'related' &&
			(this.props.post.original || this.props.post.primary_term)
		) {
			const { post, terms } = this.props;

			// eslint-disable-next-line no-unused-vars
			const primaryTerm = post.primary_term?.id ? post.primary_term : null;

			const termExists = post.original && post.original[0] && terms[post.original[0]];

			let term = termExists ? terms[post.original[0]] : false;

			if (!term && post.primary_term?.id) {
				term = post.primary_term;
			}

			if (!term.name || !term.link) {
				return null;
			}

			return (
				<Link
					href={term.originalhref ?? term.link}
					itemProp="url"
					className="post-grid-item__archive-link"
					locale={false}
				>
					<span className="post-grid-item__archive-link-text">{term.name}</span>
				</Link>
			);
		}
	};

	loadHandlerInlineSvg() {
		getInlineSvg();
	}

	render() {
		const { post, displayExcerpt } = this.props;
		let sizes = '(max-width: 1440px) 100vw, 1440px';
		let sizeClass = ' post-grid-item-sm-50';

		const image = this.props.post?.image ?? {};
		const videoThumb = post.video_thumbnail;

		const { containerClass, linkClass, titleContainClass } = PostGridItem.decorateClasses(
			this.props.type,
		);

		if (this.props.type === 'originals') {
			sizes = '50vw';
		} else if (this.props.type === 'related') {
			sizes = '(min-width: 992px) 33vw, (min-width: 576px) 50vw, 100vw';
			sizeClass = ' post-grid-item-sm-50 post-grid-item-lg-33';
		} else if (this.props.type === 'featured') {
			sizes = '(min-width: 1440px) 620px, (min-width: 576px) 50vw, 100vw';
		}

		// if we should display the excerpt
		const codedExcerpt = displayExcerpt
			? // and the excerpt field exists
			  post.excerpt && post.excerpt.rendered
				? // strip the html tags for rendering
				  striptags(post.excerpt.rendered)
				: // otherwise, take the Gutenberg post content and
				  // strip html tags,
				  striptags(post.content.rendered)
						// then get just the first 36 words
						.replace(/^(.{20}[^\s]*).*/, '$1') // get the first 36 words
			: null;

		// if there is an excerpt to render
		const excerpt = codedExcerpt
			? // convert html entities to be displayed properly
			  decodeEntities(codedExcerpt)
			: null;

		return (
			<div
				className={
					videoThumb
						? `post-grid-item${containerClass} posts-grid__item${sizeClass} post-grid-item--video`
						: `post-grid-item${containerClass} posts-grid__item${sizeClass}`
				}
			>
				<Link
					className={`post-grid-item__link${linkClass}`}
					href={post.link}
					itemProp="url"
					aria-label={post.title}
				>
					{videoThumb ? (
						<VideoThumbnail fileSrc={videoThumb} parentClass="" />
					) : (
						image.src && (
							<Image
								height={image.height}
								width={image.width}
								src={image.src}
								alt={image.alt}
								placeholder="blur"
								blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAFUlEQVR42mNkTnKsZyACMI4qpK9CAPyfC31pseXRAAAAAElFTkSuQmCC"
								sizes={sizes}
							/>
						)
					)}
				</Link>

				<div className="post-grid-item__content">
					{this.originalsArchive()}

					<Link
						className={`post-grid-item__link${linkClass}`}
						href={post.link}
						itemProp="url"
						aria-label={post.title}
						locale={false}
					>
						<div className={`post-grid-item__title-contain${titleContainClass}`}>
							{this.taxonomySupHead()}
							{this.postTitle()}
						</div>
					</Link>

					{!!excerpt && <p>{excerpt}</p>}
				</div>
			</div>
		);
	}
}

export default PostGridItem;
